import { FC } from 'react'

import classNames from 'classnames'
import { sumBy } from 'lodash'

import EmptyList from '@banx/components/EmptyList'

import { MergedProposal } from '@banx/pages/RootPage/components/Proposals/hooks'
import { isProposalEnded } from '@banx/pages/RootPage/helpers'
import { VOTE_STATUS_COLORS, formatNumbersWithCommas } from '@banx/utils'

import styles from '../ProposalPage.module.less'

interface VotesInfoBlockProps {
  data: MergedProposal
}

export const VotesInfoBlock: FC<VotesInfoBlockProps> = ({ data }) => {
  const { variants, pointsTotal } = data

  return (
    <div className={styles.contentBlock}>
      <div className={styles.votesInfoHeader}>
        <h3>Votes</h3>
        <span>{formatNumbersWithCommas(pointsTotal)}</span>
      </div>

      {isProposalEnded(data) && (
        <>
          <VoteProgressBar variants={variants} pointsTotal={parseFloat(pointsTotal)} />
          <VoteList variants={variants} pointsTotal={parseFloat(pointsTotal)} />
        </>
      )}

      {!isProposalEnded(data) && (
        <EmptyList
          className={styles.votesEmptyList}
          message="Results will be available after voting ends"
        />
      )}
    </div>
  )
}

interface VoteProgressBarrops {
  variants: MergedProposal['variants']
  pointsTotal: number
  className?: string
}

export const VoteProgressBar: FC<VoteProgressBarrops> = ({ variants, pointsTotal, className }) => {
  const MIN_PERCENTAGE = 5

  const allZeroVotes = variants.every(
    ({ votes }) => sumBy(votes, (variant) => parseFloat(variant.points)) === 0,
  )

  const percentages = variants.map(({ votes }) => {
    const totalVariantPoints = sumBy(votes, (variant) => parseFloat(variant.points))
    return allZeroVotes ? 0 : calculateVotePercentage(totalVariantPoints, pointsTotal)
  })

  let totalPercentage = percentages.reduce((acc, perc) => acc + perc, 0)
  const adjustedPercentages = percentages.map((perc) => Math.max(MIN_PERCENTAGE, perc))

  totalPercentage = adjustedPercentages.reduce((acc, perc) => acc + perc, 0)
  const scaleFactor = 100 / totalPercentage

  const finalPercentages = adjustedPercentages.map((perc) => perc * scaleFactor)

  let cumulativeWidth = 0

  return (
    <div className={classNames(styles.votesInfoProgressBar, className)}>
      {variants.map(({ name }, index) => {
        const percentage = finalPercentages[index]

        const left = cumulativeWidth
        cumulativeWidth += percentage

        return (
          <div
            key={name}
            className={styles.votesInfoProgressBarItem}
            style={{
              background: VOTE_STATUS_COLORS[index],
              width: `${percentage}%`,
              left: `${left}%`,
            }}
          />
        )
      })}
    </div>
  )
}

interface VoteListProps {
  variants: MergedProposal['variants']
  pointsTotal: number
}

const VoteList: FC<VoteListProps> = ({ variants, pointsTotal }) => {
  return (
    <div className={styles.votesInfoList}>
      {variants.map(({ name, votes }, index) => {
        const totalVariantPoints = sumBy(votes, (variant) => parseFloat(variant.points))

        const percentage = calculateVotePercentage(totalVariantPoints, pointsTotal)
        const formattedPercentage = percentage.toFixed(0)

        return (
          <div key={name} className={styles.votesInfoListItem}>
            <div className={styles.votesInfoListItemLabel}>
              <div
                className={styles.votesInfoListItemDot}
                style={{ background: VOTE_STATUS_COLORS[index] }}
              />
              <span>{name}</span>
            </div>

            <span className={styles.votesInfoListItemValue}>
              {formatNumbersWithCommas(totalVariantPoints)} ({formattedPercentage}%)
            </span>
          </div>
        )
      })}
    </div>
  )
}

const calculateVotePercentage = (voters: number, pointsTotal: number) => {
  if (pointsTotal === 0 || pointsTotal === 0) return 0

  return (voters / pointsTotal) * 100
}
